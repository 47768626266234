<template>
  <div class="publicNav">
    <!-- <div class="navList">
      <div class="like">感兴趣</div>
      <div class="already">已投递</div>
      <div class="interview">面试</div>
      <div @click="toTop">
        <span><i class="el-icon-arrow-up"></i></span> TOP
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100; //可以自己设置
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 20);
    },
  },
};
</script>
<style lang="less" scoped>
.publicNav {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 106px;
  .navList {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    div {
      padding: 9px 0;
      text-align: center;
      background: #fff;
      font-weight: 500;
      cursor: pointer;
      color: #9b9b9b;
      &:nth-child(n + 2) {
        border-top: 1px solid #d9dbdd;
      }
    }
    .like {
      color: #1890ff;
      font-weight: bold;
    }
    .already {
      color: #fd9a65;
      font-weight: bold;
    }
    .interview {
      color: #12bf48;
      font-weight: bold;
    }
  }
}
</style>